.device-item .top-controls {
    font-size: 14px;
}

.device-item .top-controls i {
    font-size: 18px;
    color: #B1B1B9;
}

.device-item .big-icon {
    font-size: 90px;
}

.device-item .middle-icon {
    font-size: 50px;
}

.device-item .big-text {
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
}

.device-item .stage-item {
    height: 10px;
}

.device-item .statistic-data {
    overflow-y: auto;
    padding-right: 13px;
    height: 150px;
}

@media (min-width: 992px) {
    .device-item .statistic-data {
        right: 15px;
        left: 15px;
    }
}

.device-item .statistic-data::-webkit-scrollbar {
    width: 6px;
}

.device-item .statistic-data::-webkit-scrollbar-thumb {
    background: #D8D8DC;
    border-radius: 100px;
}

.device-item .statistic-data::-webkit-scrollbar-thumb:hover {
    background: #B1B1B9;
}

.device-item .statistic-data-item {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
}

.device-item .statistic-data .splitter {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    opacity: 0.5;
}

.device-item .incidents-empty {
    font-size: 14px;
    line-height: 14px;
    color: #333333;
}

.device-item .title h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
    margin: 0;
}

.device-item .education-buttons .btn-link {
    color: #000;
    text-transform: none;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
}

.device-item .btn-link:hover, .device-item .btn-link:hover i, .device-item .btn-link:hover path {
    color: #F1635A;
    fill: #F1635A;
}

.device-item .progress-description {
    font-size: 14px;
    line-height: 22px;
}

.device-item .watering-at-night {
    background: rgba(0, 156, 229, 0.2);
    border-radius: 4px;
}

.device-item .watering-at-night i {
    color: #009CE5;
}

.device-item .watering-at-night-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.device-item .param-description {
    font-size: 14px;
    line-height: 22px;
}

.device-item .lighting-modes {
    background: rgba(76, 185, 114, 0.2);
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.7;
}

.device-item .lighting-modes > div {
    opacity: 0.7;
}

.device-item .lighting-modes a:hover svg path {
    fill: #F1635A;
}

.device-item .small-filter-items > div {
    padding: 2px 14px;
    margin-right: 5px;
    border: 1px solid #B1B1B9;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
}

.device-item .small-filter-items > div.active {
    background: #B1B1B9;
    color: #FFFFFF;
    cursor: default;
}

@media (max-width: 1120px) {
    .device-item .big-text {
        font-size: 30px;
    }
}

.device-item .stage-progress-items > div + div {
    padding-left: 10px;
}

.device-item .recharts-cartesian-axis-tick-value {
    font-size: 12px;
}

.device-small-block, .device-big-block  {
    font-size: 14px;
    line-height: 24px;
}

.device-small-block .icon {    
    width: 20px;
}

.device-small-block .harvesting {
    background: rgba(76, 185, 114, 0.15);
    border-radius: 4px;
}

.device-small-block .harvesting svg path {
    fill: #4CB972;
}

.water-change-time {
    display: inline-block;
    color: white;
    font-size: 12px;
    white-space: nowrap;
}

.problem-plant-item {
    border: 1px solid rgba(99, 99, 115, 0.2);
    font-size: 14px;
    height: 100%;
    min-height: 500px;
}

.problem-plant-item .device-name {
    text-decoration: underline;
}

.device-photo-item {
    font-size: 14px;
    cursor: pointer;
}

.device-photo-item img {
    max-width: 100%;
}

.plant-modal {
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;
}

.plant-modal .device-name {
    text-decoration: underline;
}

.plant-modal .modal-body {
    min-height: 500px;
}

.plant-modal .modal-header {
    margin-bottom: -55px;
    background: transparent!important;
    z-index: 1;
}

.plant-modal .modal-header .heading {
    font-size: 14px!important;
    color: white!important;
}

.plant-modal .modal-header .close path {
    fill: white;
}

.plant-problem-solution path {
    fill: #B1B1B9;
}

.program-image {
    width: 40px;
    height: 40px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
}

.plant-detail-photo {
    width: 100%;
}

.problem-icons {
    min-height: 72px;
    margin-top: -70px;
}

.problem-icons > div {
    background: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.problem-icons > div + div {
    margin-left: 13px;
}

.problem-icons svg {
    height: 25px;
}

.problem-icons path {
    fill: #636373;
}

.program-big-block {
    font-size: 14px;
    line-height: 24px;
    background: #F1F4FB;
    border: none;
    border-radius: 9px;
    height: 100%;
}

.program-big-block .title {
    font-size: 21px;
    line-height: 25px;
}

.program-big-block .title a {
    color: black;
}

.program-big-block .title .image {
    height: 67px;
    width: 67px;
    border-radius: 50%;
    float: left;
    border: 1px solid #FFFFFF;
}

.program-big-block .title button {
    outline: none;
    border: none;
    color: white;
    background: linear-gradient(187.2deg, #1DA154 -31.22%, #28CA6B 94.39%);
    border-radius: 0px 8px;
}

.program-big-block .stage-item {
    background: #1DA154;
    border-radius: 15.5px;
    color: white;
    padding: 3px;
    margin-bottom: 5px;
    opacity: 0.7;
    min-height: 34px;
    cursor: pointer;
}

.program-big-block .stage-item.active {
    opacity: 1;
}

.program-big-block .stage-item-title {
    font-weight: 500;
    font-size: 12px;
    margin-left: 33px;
}

.program-big-block .stage-item-icon {
    float: left;
    height: 28px;
    width: 28px;
    background-color: white;
    color: black;
    border-radius: 50%;
    text-align: center;
    padding-top: 2px;
}

.program-big-block .stage-param {
    display: block;
    background: #FFFFFF;
    border: 1px solid #B9C1D9;
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
    color: #212529;
}

.program-big-block .stage-param-title {
    margin-left: 35px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #737373;
}

.program-big-block .stage-param-value {
    margin-left: 35px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.program-big-block .current-stage-title {
    font-weight: 500;
    font-size: 14px;
}