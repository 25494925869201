.auth-form, .register-form {
    background: #FFFFFF;
    max-width: 640px;
    margin: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 40px;
}

.auth-form .h4, .register-form .h4 {
    font-size: 28px;
    line-height: 28px;
}

.auth-form .h6, .register-form .h6 {
    font-size: 15px;
    line-height: 27px;
}

.auth-form .auth-nav a, .register-form .reg-nav a {
    font-size: 15px;
}

.register-form .policy {
    font-size: 14px;
}

@media (max-width: 740px) {
    .auth-form, .register-form {
        height: 100%;
        border-radius: 0;        
        padding: 20px 40px;
    }

    .auth-form .h4, .register-form .h4 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    .auth-form .h6, .register-form .h6 {
        font-size: 14px;
        line-height: 20px;
    }
}