.modal-dialog.modal-notify.modal-app .modal-body {
    padding: 1rem 1.5rem;
}

.modal-dialog.modal-notify.modal-app .modal-header {
    background-color: white;
    border: 0;
    box-shadow: none;
    padding-bottom: 0;
}

.modal-dialog.modal-notify.modal-app .heading {
    padding: .3rem;
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
}

.modal-dialog.modal-notify.modal-app .close {
    font-size: 17px;
}

.modal-dialog.modal-notify.modal-app button.close:hover path{
    fill: var(--main-red-color)
}

.modal.show {
    display: block;
}