.modal {
    height: auto!important;
}

.success-color {
    background-color: #4CB972!important;
}

.danger-color {
    background-color: #F1635A!important;
}

.btn.btn-success {
    background: #4CB972!important;
    text-transform: none;
    font-weight: 600;
}

.btn.btn-danger {
    background: #F1635A!important;
    text-transform: none;
    font-weight: 600;
}

.btn.btn-warning {
    text-transform: none;
    font-weight: 600;
}

.btn.btn-grey {
    color: #fff;
    background: #B1B1B9!important;
    text-transform: none;
    font-weight: 600;
}

.btn.btn-link {
    text-transform: none;
}

.btn-outline-danger {
    color: #F1635A !important;
    border: 2px solid #F1635A !important;
}

.select-label {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-outline .select-label {
    position: absolute;
    top: 0;
    left: .75rem;
    padding: 0px 3px;
    pointer-events: none;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    color: rgba(0,0,0,.6);
    margin-bottom: 0;
    margin-top: .37rem;
}

.select-label.active {
    -webkit-transform: translateY(-1rem) translateY(.1rem) scale(.8);
    transform: translateY(-1rem) translateY(.1rem) scale(.8);
    background: white;
}

.bg-gray {
    background-color: #BDBDBD;
}