@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto:100,300,400);
.auth-form, .register-form {
    background: #FFFFFF;
    max-width: 640px;
    margin: auto;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 40px;
}

.auth-form .h4, .register-form .h4 {
    font-size: 28px;
    line-height: 28px;
}

.auth-form .h6, .register-form .h6 {
    font-size: 15px;
    line-height: 27px;
}

.auth-form .auth-nav a, .register-form .reg-nav a {
    font-size: 15px;
}

.register-form .policy {
    font-size: 14px;
}

@media (max-width: 740px) {
    .auth-form, .register-form {
        height: 100%;
        border-radius: 0;        
        padding: 20px 40px;
    }

    .auth-form .h4, .register-form .h4 {
        font-size: 1.25rem;
        line-height: 1.25rem;
    }

    .auth-form .h6, .register-form .h6 {
        font-size: 14px;
        line-height: 20px;
    }
}
.modal {
    height: auto!important;
}

.success-color {
    background-color: #4CB972!important;
}

.danger-color {
    background-color: #F1635A!important;
}

.btn.btn-success {
    background: #4CB972!important;
    text-transform: none;
    font-weight: 600;
}

.btn.btn-danger {
    background: #F1635A!important;
    text-transform: none;
    font-weight: 600;
}

.btn.btn-warning {
    text-transform: none;
    font-weight: 600;
}

.btn.btn-grey {
    color: #fff;
    background: #B1B1B9!important;
    text-transform: none;
    font-weight: 600;
}

.btn.btn-link {
    text-transform: none;
}

.btn-outline-danger {
    color: #F1635A !important;
    border: 2px solid #F1635A !important;
}

.select-label {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.form-outline .select-label {
    position: absolute;
    top: 0;
    left: .75rem;
    padding: 0px 3px;
    pointer-events: none;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: all .2s ease-out;
    color: rgba(0,0,0,.6);
    margin-bottom: 0;
    margin-top: .37rem;
}

.select-label.active {
    -webkit-transform: translateY(-1rem) translateY(.1rem) scale(.8);
    transform: translateY(-1rem) translateY(.1rem) scale(.8);
    background: white;
}

.bg-gray {
    background-color: #BDBDBD;
}
.wrapper {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

body > .wrapper {
    height: auto;
    min-height: 100%;
}

.main-header {
    position: relative;
    max-height: 100px;
    z-index: 1030;
}

.main-header .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 12px 15px;
    color: #B1B1B9!important;
}

.main-header .navbar {
    transition: margin-left .3s ease-in-out;
    margin-bottom: 0;
    margin-left: 275px;
    border: none;
    min-height: 50px;
    border-radius: 0;
    display: block;
    padding: 0;
    background: white;
    box-shadow: none;
}

.main-header .language-selector {
    float: left;
    padding: 12px 2px;
}

.main-header .navbar-custom-menu, .main-header .navbar-right {
    float: right;
}

.main-header .avatar {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.main-header .nav-link {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding-top: 13px;
    height: 50px;
    color: #636373;
}

.main-header .nav-link:hover {
    background: rgba(0,0,0,0.1);
}

.main-header .links {
    flex-direction: row;
}

.main-sidebar {
    background-color: #636373;
}

.main-sidebar a, .main-sidebar path{    
    color: rgba(169, 169, 178, 0.7);
    fill: rgba(169, 169, 178, 0.7);
}

.main-sidebar a:hover, .main-sidebar a:hover path {
    background-color: #545469;
    color: white;
    fill: white;
}


.main-sidebar a svg {
    width: 30px;
}

.main-sidebar a {
    white-space: nowrap;
    padding: .85rem 1rem;
    display: block;
}

.main-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 50px;
    min-height: calc(100% + 50px);
    width: 275px;
    z-index: 810;
    transition: width .3s ease-in-out,-webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out,width .3s ease-in-out;
    transition: transform .3s ease-in-out,width .3s ease-in-out,-webkit-transform .3s ease-in-out;
}

.main-header .logo, .main-sidebar .logo {
    transition: width .3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    width: 275px;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;
    background: white;
    color:#636373;
}

.main-header .logo-center {
    display: none;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    font-weight: 300;
    overflow: hidden;
    color:#636373;
    margin-left: 20px;
}

.main-header .logo .logo-lg, .main-sidebar .logo .logo-lg {
    display: block;
}

.main-header .logo .logo-mini, .main-sidebar .logo .logo-mini  {
    display: none;
}

.main-sidebar .logo {
    color: white;
    background: #636373;
    margin-top: -50px;
}

.main-header.sidebar-collapse .logo > .logo-mini, .main-sidebar.sidebar-collapse .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 35px;
}

.content-wrapper, .main-footer {
    transition: margin .3s ease-in-out,-webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out,margin .3s ease-in-out;
    transition: transform .3s ease-in-out,margin .3s ease-in-out,-webkit-transform .3s ease-in-out;
    margin-left: 275px;
    z-index: 820;
}

.content-wrapper {
    min-height: calc(100vh - 101px);
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-collapse .sidebar-menu li:hover {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.sidebar-collapse .sidebar-menu li:hover  span {
    display: inline;
}

.main-header .dropdown-item:active {
    color: #fff!important;
    text-decoration: none;
    background-color: #636373;
}

.sidebar-menu span {
    font-weight: 600;
    margin-left: 15px;
    font-size: 14px;
    line-height: 24px;
}

.navbar-notifications {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    min-width: 10rem;
    padding: .5rem 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    width: 280px;
    padding-bottom: 0;
}

@media (min-width: 576px) {
    .navbar-notifications {
        width: 350px;
    }
}

@media (min-width: 1200px) { 
    .navbar-notifications {
        width: 400px;
    }
}

.navbar-notifications .notification-item .date {
    font-size: 12px;
    line-height: 12px;
    color: #636373;
}

.navbar-notifications .notification-item + .notification-item {
    border-top: 1px dashed #E0E0E3;
}

.navbar-notifications .notification-item .text {
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.navbar-notifications .critical-state {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 2px;
    background-color: #4CB972;
}

.navbar-notifications .critical-state.critical {
    background-color: #F1635A;
}

.navbar-notifications .notification-more {
    background-color: #EEEEF0;
    color: rgba(91, 91, 91, 0.5);
    padding: 10px;
    font-size: 30px;
    font-weight: 300;
}

.notification-more a {
    color: rgba(91, 91, 91, 0.5);
}

.navbar-notifications .notification-more span {    
    margin-top: -15px;
}

.notification-link {
    color: #333333;
    text-decoration: underline;
    padding: 0!important;
}

.notification-count {
    background: #F1635A;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 7px;
    color: white;
    font-size: 11px;
    line-height: 12px;
    padding: 4px 6px;
}

@media (max-width: 767px) {
    .main-sidebar {
        -webkit-transform: translate(-275px, 0);
        transform: translate(-275px, 0);
    }

    .main-sidebar.sidebar-open {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .main-header .logo {
        display: none;
    }

    .main-header .navbar {
        width: 100%;
        float: none;
    }

    .main-header .logo-center {
        display: block;
    }


    .content-wrapper, .main-footer {
        margin-left: 0;
    }

    .main-header .navbar {
        margin: 0;
    }
}

@media (min-width: 768px) {
    .sidebar-collapse.main-sidebar {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 65px !important;
        z-index: 850;
    }

    .sidebar-collapse.main-header .navbar {
        margin-left: 65px;
    }

    .main-header.sidebar-collapse .logo, .main-sidebar.sidebar-collapse .logo {
        width: 65px;
    }

    .sidebar-collapse .sidebar-menu span {
        display: none;
    }

    .content-wrapper.sidebar-collapse, .main-footer.sidebar-collapse {
        margin-left: 65px !important;
        z-index: 840;
    } 

    .main-header.sidebar-collapse .logo>.logo-lg, .main-sidebar.sidebar-collapse .logo>.logo-lg {
        display: none;
    }
}
.device-item .top-controls {
    font-size: 14px;
}

.device-item .top-controls i {
    font-size: 18px;
    color: #B1B1B9;
}

.device-item .big-icon {
    font-size: 90px;
}

.device-item .middle-icon {
    font-size: 50px;
}

.device-item .big-text {
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
}

.device-item .stage-item {
    height: 10px;
}

.device-item .statistic-data {
    overflow-y: auto;
    padding-right: 13px;
    height: 150px;
}

@media (min-width: 992px) {
    .device-item .statistic-data {
        right: 15px;
        left: 15px;
    }
}

.device-item .statistic-data::-webkit-scrollbar {
    width: 6px;
}

.device-item .statistic-data::-webkit-scrollbar-thumb {
    background: #D8D8DC;
    border-radius: 100px;
}

.device-item .statistic-data::-webkit-scrollbar-thumb:hover {
    background: #B1B1B9;
}

.device-item .statistic-data-item {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
}

.device-item .statistic-data .splitter {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    margin-bottom: 5px;
    opacity: 0.5;
}

.device-item .incidents-empty {
    font-size: 14px;
    line-height: 14px;
    color: #333333;
}

.device-item .title h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
    margin: 0;
}

.device-item .education-buttons .btn-link {
    color: #000;
    text-transform: none;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
}

.device-item .btn-link:hover, .device-item .btn-link:hover i, .device-item .btn-link:hover path {
    color: #F1635A;
    fill: #F1635A;
}

.device-item .progress-description {
    font-size: 14px;
    line-height: 22px;
}

.device-item .watering-at-night {
    background: rgba(0, 156, 229, 0.2);
    border-radius: 4px;
}

.device-item .watering-at-night i {
    color: #009CE5;
}

.device-item .watering-at-night-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.device-item .param-description {
    font-size: 14px;
    line-height: 22px;
}

.device-item .lighting-modes {
    background: rgba(76, 185, 114, 0.2);
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.7;
}

.device-item .lighting-modes > div {
    opacity: 0.7;
}

.device-item .lighting-modes a:hover svg path {
    fill: #F1635A;
}

.device-item .small-filter-items > div {
    padding: 2px 14px;
    margin-right: 5px;
    border: 1px solid #B1B1B9;
    border-radius: 100px;
    font-size: 14px;
    cursor: pointer;
}

.device-item .small-filter-items > div.active {
    background: #B1B1B9;
    color: #FFFFFF;
    cursor: default;
}

@media (max-width: 1120px) {
    .device-item .big-text {
        font-size: 30px;
    }
}

.device-item .stage-progress-items > div + div {
    padding-left: 10px;
}

.device-item .recharts-cartesian-axis-tick-value {
    font-size: 12px;
}

.device-small-block, .device-big-block  {
    font-size: 14px;
    line-height: 24px;
}

.device-small-block .icon {    
    width: 20px;
}

.device-small-block .harvesting {
    background: rgba(76, 185, 114, 0.15);
    border-radius: 4px;
}

.device-small-block .harvesting svg path {
    fill: #4CB972;
}

.water-change-time {
    display: inline-block;
    color: white;
    font-size: 12px;
    white-space: nowrap;
}

.problem-plant-item {
    border: 1px solid rgba(99, 99, 115, 0.2);
    font-size: 14px;
    height: 100%;
    min-height: 500px;
}

.problem-plant-item .device-name {
    text-decoration: underline;
}

.device-photo-item {
    font-size: 14px;
    cursor: pointer;
}

.device-photo-item img {
    max-width: 100%;
}

.plant-modal {
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;
}

.plant-modal .device-name {
    text-decoration: underline;
}

.plant-modal .modal-body {
    min-height: 500px;
}

.plant-modal .modal-header {
    margin-bottom: -55px;
    background: transparent!important;
    z-index: 1;
}

.plant-modal .modal-header .heading {
    font-size: 14px!important;
    color: white!important;
}

.plant-modal .modal-header .close path {
    fill: white;
}

.plant-problem-solution path {
    fill: #B1B1B9;
}

.program-image {
    width: 40px;
    height: 40px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 100px;
}

.plant-detail-photo {
    width: 100%;
}

.problem-icons {
    min-height: 72px;
    margin-top: -70px;
}

.problem-icons > div {
    background: white;
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.problem-icons > div + div {
    margin-left: 13px;
}

.problem-icons svg {
    height: 25px;
}

.problem-icons path {
    fill: #636373;
}

.program-big-block {
    font-size: 14px;
    line-height: 24px;
    background: #F1F4FB;
    border: none;
    border-radius: 9px;
    height: 100%;
}

.program-big-block .title {
    font-size: 21px;
    line-height: 25px;
}

.program-big-block .title a {
    color: black;
}

.program-big-block .title .image {
    height: 67px;
    width: 67px;
    border-radius: 50%;
    float: left;
    border: 1px solid #FFFFFF;
}

.program-big-block .title button {
    outline: none;
    border: none;
    color: white;
    background: linear-gradient(187.2deg, #1DA154 -31.22%, #28CA6B 94.39%);
    border-radius: 0px 8px;
}

.program-big-block .stage-item {
    background: #1DA154;
    border-radius: 15.5px;
    color: white;
    padding: 3px;
    margin-bottom: 5px;
    opacity: 0.7;
    min-height: 34px;
    cursor: pointer;
}

.program-big-block .stage-item.active {
    opacity: 1;
}

.program-big-block .stage-item-title {
    font-weight: 500;
    font-size: 12px;
    margin-left: 33px;
}

.program-big-block .stage-item-icon {
    float: left;
    height: 28px;
    width: 28px;
    background-color: white;
    color: black;
    border-radius: 50%;
    text-align: center;
    padding-top: 2px;
}

.program-big-block .stage-param {
    display: block;
    background: #FFFFFF;
    border: 1px solid #B9C1D9;
    box-sizing: border-box;
    border-radius: 8px;
    height: 100%;
    color: #212529;
}

.program-big-block .stage-param-title {
    margin-left: 35px;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #737373;
}

.program-big-block .stage-param-value {
    margin-left: 35px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.program-big-block .current-stage-title {
    font-weight: 500;
    font-size: 14px;
}
.modal-dialog.modal-notify.modal-app .modal-body {
    padding: 1rem 1.5rem;
}

.modal-dialog.modal-notify.modal-app .modal-header {
    background-color: white;
    border: 0;
    box-shadow: none;
    padding-bottom: 0;
}

.modal-dialog.modal-notify.modal-app .heading {
    padding: .3rem;
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
}

.modal-dialog.modal-notify.modal-app .close {
    font-size: 17px;
}

.modal-dialog.modal-notify.modal-app button.close:hover path{
    fill: var(--main-red-color)
}

.modal.show {
    display: block;
}
.toast-progress-bar {
    background: none;
    background-color: grey!important;
    opacity: 0.35!important;
}

.toast {
    min-height: 40px!important;
}
:root {
    --main-red-color: #F1635A;
}

body {
    min-width: 450px;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
  
html,
body {
  height: 100%;
  background: #F0F0F1;  
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
}

#root {
    height: 100%;
}

.no-user-select {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.msg-warning {
    border: 1px solid #F1635A;
    border: 1px solid var(--main-red-color);
    background-color: #feecec;
    padding: 8px 10px;
    color: #F1635A;
    color: var(--main-red-color);
    border-radius: .25rem;
}

.btn-rounded {
    border-radius: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.btn.btn-flat {
    font-weight: 500;
    color: inherit;
    background-color: transparent;
    box-shadow: none;
}

.spoiler-not-visible {
    display: none;
}

.spoiler-opened .spoiler-not-visible {
    display: block;
}

.spoiler-opened .spoiler-visible {
    display: none;
}

.react-select input {
    border: none!important;
    box-shadow: none!important;
}

.device-modal-program-stage-params {
    font-size: 13px;
    line-height: 18px;
}

.device-modal-program-stage-params i {
    width: 25px;
}

.dropdown-item > i {
    width: 20px;
}

ul.errors {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.page-title {
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
    background-color: white;
}

.progress-container {
    width: 100%;
    height: 6px;
    background: rgba(99, 99, 115, 0.15);
    border-radius: 100px;
}

.progress-value {
    background-color: #4CB972;
    height: 100%;
    border-radius: 100px;
}


.app-form .react-select input {
    height: 34px;
}

.app-form .row-label {
    font-size: 15px;
    line-height: 25px;
}

.app-form .row-btn {
    height: 48px;
}

.text-transform-none {
    text-transform: none;
}

.stage-list-item {
    border: 2px dashed rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px;
}

.stage-param-title-list .stage-param-item {
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px;
}

.stage-list .stage-list-item.active {
    background: rgba(76, 185, 114, 0.2);
    border: 2px dashed #4CB972;
}

.stage-param-title-list .stage-param-item.active {
    background: rgba(76, 185, 114, 0.2);
    border: 2px solid #4CB972;
}

.sortable-helper .stage-list-item {
    background-color: white;
    border: 2px dashed #4CB972;
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
}

.sortable-helper .stage-item-actions {
    display: none;
}

.stage-list .stage-item-actions button {
    cursor: pointer;
    height: 100%;
}

.sortable-helper {
    z-index: 9999;
}

.wifi-indicator {
    width: 40px;
    display: inline-flex;
    align-items: flex-end;
    font-size: 12px;
    line-height: 12px;
}

.wifi-indicator > div {
    background-color: #C4C4C4;
    width: 25%;
}

.wifi-indicator > div + div {
    margin-left: 2px;
}

.wifi-indicator-description {
    margin-left: .5rem;
    vertical-align: super;
}

.default-block {
    background: #FFFFFF;
    border: 1px solid rgba(99, 99, 115, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
}

.default-block .block-title {
    font-size: 17px;
    line-height: 17px;
}

.default-block.page-title {
    font-size: 20px;
}

.default-block .block-title svg {
    vertical-align: baseline;
}

.default-block .block-title-value {
    margin-left: 5px;
    font-weight: 600;
}

.admin-block {
    opacity: 0.9;
    color: #636373;
}

.admin-block:hover {
    opacity: 1;
    color: #636373;
}

.admin-block:visited {
    color: #636373;
}

.admin-block span {
    font-weight: 600;
    font-size: 20px;
}

#app .link {
    color: #007bff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

#app .link:hover, .link:hover path {
    color: #F1635A!important;
    color: var(--main-red-color)!important;
    fill: #F1635A!important;
    fill: var(--main-red-color)!important;
}

.link-hover:hover, .link-hover:hover path{
    color: #F1635A!important;
    color: var(--main-red-color)!important;
    fill: #F1635A!important;
    fill: var(--main-red-color)!important;
}

.checkbox-itself, .radio-itself {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
}

.checkbox-itself[disabled] ~ .checkbox-styled {
  cursor: not-allowed; }

.checkbox-styled {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 0;
}

.checkbox-itself:focus ~ .checkbox-styled, .radio-itself:focus + .radio-styled {
    border-color: #00a8ff;
}

.toggle {
    width: 45px;
    height: 22px;
    position: relative;
    transition: .3s;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 15px;
    overflow: hidden;
}
.checkbox-itself:checked + .toggle {background: #82c250}
.checkbox-itself:not(:checked) + .toggle {background: #e74c3c}
.toggle:before {
    position: absolute;
}

.toggle:after {
    content: "";
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    transition: .3s;
    top: 1px;
}

.checkbox-itself:checked + .toggle:before{
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='12' height='9' fill='%23fff'%3E%3Cpolygon points='10.5,0.5 5,6 1.5,2.5 0,4 5,9 12,2'/%3E%3C/svg%3E");
    left: 5px;
    border: 0px solid silver;
}
.checkbox-itself:not(:checked) + .toggle:before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' height='10' fill='%23fff'%3E%3Cpolygon points='10,1.4 8.6,0 5,3.6 1.4,0 0,1.4 3.6,5 0,8.6 1.4,10 5,6.4 8.6,10 10,8.6 6.4,5'/%3E%3C/svg%3E");
    right: 5px;
    border: 0px solid silver;
    left:25px;
}
.checkbox-itself:checked + .toggle.lock:before{
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='13' height='11' fill='%23fff'%3E%3Cpath d='M8.8,5.2C8.6,5.1,8.5,5,8.2,5H0.7C0.5,5,0.4,5.1,0.2,5.2C0.1,5.4,0,5.5,0,5.7v4.5c0,0.2,0.1,0.4,0.2,0.5 C0.4,10.9,0.5,11,0.7,11h7.5c0.2,0,0.4-0.1,0.5-0.2C8.9,10.6,9,10.5,9,10.2V5.7C9,5.5,8.9,5.4,8.8,5.2z'/%3E%3Cpath d='M12,1c-0.7-0.7-1.5-1-2.5-1S7.7,0.3,7,1C6.3,1.7,6,2.5,6,3.5V5h1.5V3.5c0-0.6,0.2-1,0.6-1.4 c0.4-0.4,0.9-0.6,1.4-0.6s1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4V5H13V3.5C13,2.5,12.7,1.7,12,1z'/%3E%3C/svg%3E");
    left: 7px;
}
.checkbox-itself:not(:checked) + .toggle.lock:before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='9' height='11' fill='%23fff'%3E%3Cpath d='M8.8,5.2C8.6,5.1,8.5,5,8.2,5H8V3.5c0-1-0.3-1.8-1-2.5C6.3,0.3,5.5,0,4.5,0C3.5,0,2.7,0.3,2,1 C1.3,1.7,1,2.5,1,3.5V5H0.7C0.5,5,0.4,5.1,0.2,5.2C0.1,5.4,0,5.5,0,5.7v4.5c0,0.2,0.1,0.4,0.2,0.5C0.4,10.9,0.5,11,0.7,11h7.5  c0.2,0,0.4-0.1,0.5-0.2C8.9,10.6,9,10.5,9,10.3V5.7C9,5.5,8.9,5.4,8.8,5.2z M6.5,5h-4V3.5c0-0.6,0.2-1,0.6-1.4  c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4V5z'/%3E%3C/svg%3E");
    right: 7px;
}
.checkbox-itself:checked + .toggle:after{
    left: 24px;
}

.checkbox-itself:not(:checked) + .toggle:after {
    left: 1px;
}

.checkbox-itself:checked ~ .checkbox-styled:before {
    content: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='%23111b25'%3E%3Cpolygon points='13.8,0 5.5,12.4 1.5,7.8 0.5,9.4 5.6,15 15,1.1'%3E%3C/polygon%3E%3C/svg%3E");
    position: absolute;
    line-height: 0;
    top: -2px;
    width: 15px;
    height: 15px;
}

.upload-block > div {
    width: 100%;    
    font-size: 40px;
    border: 2px dashed rgba(0,0,0,0.2);
    padding: 30px 10px;
    cursor: pointer;
}

.upload-block > div:hover {
    border: 2px dashed rgba(0,0,0,0.5);
}

.rotate-right {
    -webkit-animation:spin 1.5s linear infinite;
            animation:spin 1.5s linear infinite;
}

@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.hide-system-programs {
    font-size: 14px;
    margin-top: 2px;
}

.msg-block {
    padding: 15px;
    display: inline-block;
    border: 1px solid;
    font-size: 14px;
}

.msg-block.danger {
    background-color: #FFCDD2;
    color: #F44336;
}

.msg-block.success {
    background-color: #C8E6C9;
    color: #43A047;
}

.error_focused > div {
    border-color: red;
}
.select-label.error_focused {
    color: red;
}

.select-lg > div {
    padding-top: 3px;
    padding-bottom: 3px;
}

.select-lg + .select-label:not(.active) {
    padding-top: 5px;
}

.filter-search {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    height: 39px;
    border-radius: 2px;
    background: #fff;
    box-sizing: border-box;
    outline: 0;
    width: 100%;
    min-width: 300px;
    border: 1px solid rgba(0,0,0,.1);
}

.filter-modal {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 100;
    background-color: white;
    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
    max-width: 1000px;
    opacity: 0;
}

@media (min-width: 1200px) {
    .filter-modal {
        min-width: 740px;
    }
}

.filter-modal > div {
    /*min-height: 300px;*/
}

.filter-search-text {
    position: relative;
    box-sizing: border-box;
    margin: 4px 1px;
    padding: 0 50px 0 15px;
    width: 100%;
    height: 30px;
    outline: 0;
    border: 0;
    border-radius: 2px;
    transition: background .3s;
    font-size: 15px;
    /*font: 15px/30px "Helvetica Neue",Arial,Helvetica,sans-serif;
    z-index: 1000;*/
}



.main-ui-item-icon-block {
    /*position: absolute;
    top: 0;
    right: 3px;
    padding: 0 5px;
    */
    display: flex;
    justify-content: center;
    height: 39px;
    box-sizing: border-box;
}

.main-ui-item-icon-block > span {
    width: 30px;
}

.main-ui-item-icon {
    transition: all 300ms;
    cursor: pointer;
    opacity: 0.3;
    padding: 0 7px;
    margin: auto;
}

.main-ui-item-icon:hover {
    opacity: 1!important;
}

.main-ui-item-clear.hide {
    margin-right: -52px;
}

.main-ui-filter-wrapper {
    overflow: hidden;
    height: 100%;
    color: #535c69;
}

.main-ui-filter-inner-container {
    position: relative;
    margin: 0;
    padding-bottom: 100px;
    min-height: 100%;
    background: #fff;
    box-sizing: border-box;
}

.main-ui-filter-sidebar {
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
    width: 214px;
    height: 100%;
    border-right: 1px solid #e7eaec;
    background: #f8fafb;
    text-transform: uppercase;
}

.main-ui-filter-field-container {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px 25px 0 25px;
    min-height: 200px;
    height: 100%;
    background: #fff;
}

.main-ui-filter-field-container-list {
    margin: 0 -25px 0 -25px;
}

.main-ui-filter-bottom-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 780px;
    width: 100%;
    height: 100px;
}

.main-ui-filter-sidebar-title {
    border-bottom: 1px solid #e7eaec;
    text-align: center;
}

.main-ui-filter-sidebar-title-item {
    margin: 9px 0 5px;
    color: #a0a5ab;
    font-size: 10px;
    text-transform: uppercase;
}

.main-ui-filter-sidebar-item-container {
    font: 12px 'OpenSans-Semibold',"Helvetica Neue",Arial,Helvetica,sans-serif;
}

.main-ui-filter-sidebar:after {
    position: absolute;
    left: 0;
    width: 214px;
    height: 10000px;
    border-right: 1px solid #e7eaec;
    background: #f8fafb;
    content: '';
}

.main-ui-filter-add-container {
    position: absolute;
    bottom: 21px;
    left: 20px;
    width: 182px;
    height: 25px;
    opacity: .8;
}

.main-ui-filter-add-item {
    display: inline-block;
    overflow: hidden;
    width: 155px;
    color: #212121;
    vertical-align: middle;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    font: 11px/26px 'OpenSans-Semibold',"Helvetica Neue",Arial,Helvetica,sans-serif;
    opacity: .6;
    cursor: pointer;
    transition: opacity .3s ease;
}

.main-ui-filter-add-edit {
    display: inline-block;
    float: right;
    width: 25px;
    height: 25px;
    vertical-align: middle;
    opacity: .6;
    cursor: pointer;
    transition: opacity .3s ease;
    font-size: 13px;
}

.main-ui-filter-reset-link {
    cursor: pointer;
    color: #80868e;
    font-size: 11px;
    opacity: .7;
}

.main-ui-filter-reset-link:hover {
    opacity: 1;
}

.main-ui-filter-add-item:hover, .main-ui-filter-add-edit:hover {
    opacity: 1;
}

.main-ui-disable.main-ui-filter-add-container .main-ui-filter-add-item {
    display: none;
    position: absolute;
    opacity: 0;
}

.main-ui-disable.main-ui-filter-add-container {
    opacity: 1;
    z-index: 1;
}

.main-ui-disable.main-ui-filter-add-container * {
    transition: none;
}
.main-ui-filter-field-button-inner {
    margin: 15px 0 0 0;
    position: relative;
}

.main-ui-filter-field-add {
    padding: 0 0 5px;
}

.main-ui-filter-field-add-item {
    border-bottom: 1px dashed transparent;
    color: #1058d0;
    font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
    cursor: pointer;
    transition: opacity .2s,border-bottom .2s;
}

.main-ui-filter-field-add-item:hover {
    border-bottom: 1px dashed #1058d0;
    opacity: .8;
}

.main-ui-filter-field-restore-items {
    font: 13px "Helvetica Neue",Arial,Helvetica,sans-serif;
    color: rgba(83,95,105,.5);
    border-bottom: 1px dashed transparent;
    transition: opacity .2s,border-bottom .2s;
    cursor: pointer;
    margin-left: 16px;
}

.main-ui-filter-field-restore-items:hover {
    border-bottom: 1px dashed rgba(83,95,105,.5);
    opacity: .8;
}

.main-ui-filter-popup-field-list {
    max-width: 630px;
    padding: 25px;
    box-sizing: border-box;
}

.main-ui-filter-field-list-3-column {
    -webkit-column-count: 3;
    column-count: 3;
}

.main-ui-select-inner-item {
    padding: 0 6px 0 3px;
    min-height: 33px;
    font: 14px/33px "Helvetica Neue",Arial,Helvetica,sans-serif;
    color: #80868e;
    transition: background .3s;
    cursor: pointer;
    margin-bottom: 0;
}

.main-ui-select-inner-item:hover {
    background: #f3f6f8;
}

.main-ui-filter-field-list-item {
    height: auto!important;
    min-height: 33px!important;
    min-width: 185px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

.main-ui-filter-field-list-item .main-ui-select-inner-label {
    position: relative;
    line-height: 19px;
    width: auto;
    height: auto;
    margin-top: 6px;
    margin-left: 27px;
    word-break: break-word;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    display: inline-block;
}

.main-ui-select-inner-label:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 9px;
    left: 4px;
    height: 15px;
    width: 18px;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2214%22%20height%3D%2214%22%20viewBox%3D%220%200%2014%2014%22%3E%3Crect%20width%3D%2213%22%20height%3D%2213%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22evenodd%22%20stroke%3D%22%23C6CDD3%22%20rx%3D%222%22/%3E%3C/svg%3E);
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.main-ui-filter-field-list-item .main-ui-select-inner-label::before {
    position: absolute;
    top: 2px;
    left: -26px;
}

.main-ui-checked .main-ui-select-inner-label:before {
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2217%22%20height%3D%2215%22%20viewBox%3D%220%200%2017%2015%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20width%3D%2213%22%20height%3D%2213%22%20x%3D%22.5%22%20y%3D%221.125%22%20fill%3D%22%23FFF%22%20stroke%3D%22%23259EC6%22%20rx%3D%222%22/%3E%3Cpath%20fill%3D%22%23259EC6%22%20d%3D%22M7.53%208.487l-3.775-3.6-1.25%201.368%204.94%205.01.075-.074.286.335%208.694-10.4L15.094%200%207.53%208.487z%22/%3E%3C/g%3E%3C/svg%3E);
    background-repeat: no-repeat;
    background-position: 2px 0;
}

.filter-item {
    display: flex;
    font-weight:300;
    font-size: 1rem;
    font-family: Roboto,sans-serif;
}

.filter-item > span {
    padding: 12px 8px;
}

.filter-item > div {
    width: calc(100% - 60px);
}

.filter-item.ui-sortable-helper {
    background: #f8fafb;
}

.main-ui-filter-icon-grab {
    cursor: move;
    color: #535c69;
}

.filter-item .main-ui-filter-icon-grab, .filter-item .main-ui-filter-field-delete {
    opacity: 0;
}

.filter-item:hover .main-ui-filter-icon-grab, .filter-item:hover .main-ui-filter-field-delete {
    opacity: .4;
}

.main-ui-filter-sidebar .main-ui-item-icon {
    display: none;
}

.main-ui-filter-sidebar-item.main-ui-filter-edit .main-ui-item-icon {
    display: block;
}

.main-ui-filter-sidebar-item .main-ui-filter-icon-edit {
    color: #535c69;
}

.main-ui-filter-sidebar-item .main-ui-delete {
    color: #535c69;
}

.filter-preset-item {
    font: 14px/27px "Helvetica Neue",Arial,Helvetica,sans-serif;
    margin: 4px;
    display: flex;
    background: #bcedfc;
    padding: 2px 7px;
    border-radius: 2px;
}

.filter-preset-item .preset-remove {    
    cursor: pointer;
    opacity: .4;
    padding: 0px 6px;
    margin-right: -5px;
}

.filter-preset-item .preset-remove:hover {
    opacity: .8;
}

.app-filter-actions > button {
    padding: 0 2.14rem;
    height: 39px;
}

.main-ui-filter-sidebar-item {
    position: relative;
    border-bottom: 1px solid #e7eaec;
    cursor: pointer;
    display: flex;
    background: #f8fafb;
    -webkit-transform: translate3d(0px,0px,0px);
    transform: translate3d(0px,0px,0px);
    font: 12px 'OpenSans-Semibold',"Helvetica Neue",Arial,Helvetica,sans-serif;
}

.main-ui-filter-sidebar-item.main-ui-filter-current-item {
    color: #0fa7d7;
    cursor: default;
}

.main-ui-filter-new-filter {
    display: none;
}

.main-ui-filter-sidebar-edit-control {
    position: relative;
    box-sizing: border-box;
    padding: 0 9px;
    outline: 0;
    border: 0;
    background: transparent;
    font: 12px/38px 'OpenSans-Semibold',"Helvetica Neue",Arial,Helvetica,sans-serif;
    transition: all .3s ease-in-out;
}

.main-ui-filter-edit-mask {
    border-right: 0;
    background: #fff;
}

.main-ui-filter-sidebar-item.main-ui-filter-edit.main-ui-filter-current-item.main-ui-filter-edit-text .main-ui-filter-edit-mask {
    display: block;
}

.main-ui-filter-new-filter.main-ui-filter-sidebar-item {
    background: #fff;
    border-bottom: 0;
}

.main-ui-filter-error {
    -webkit-animation:fieldError 500ms ease;animation:fieldError 500ms ease
}

@-webkit-keyframes fieldError{0%{border-color:#f54819}100%{border-color:#e7eaec}}
@keyframes fieldError{0%{border-color:#f54819}100%{border-color:#e7eaec}}

.main-ui-filter-sidebar-item-text {
    position: relative;
    display: inline-block;
    overflow: hidden;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    transition: color .3s ease;
}

.main-ui-filter-sidebar-item-text-container {
    text-transform: uppercase;
}

.main-ui-filter-sidebar-item-text-container > span {
    vertical-align: bottom;
}

.main-ui-filter-sidebar-item-input {
    display: block;
    padding: 0;
    border: 0;
    background: transparent;
    font-weight: 600;
    color: #0fa7d7;
}

.main-ui-filter-sidebar-item-input:focus {
    outline: 0;
}

.main-ui-disable {
    opacity: .3;
    cursor: default;
    pointer-events: none;
}

.main-ui-filter-edit-text .main-ui-filter-sidebar-item-text {
    display: none;
}

.main-ui-filter-edit-text .main-ui-filter-sidebar-item-input {
    display: block;
    max-width: 145px;
}

.filter-preset-item .filter-preset-item-text {
    max-width: 180px;
}

.filter-preset-item-text {
    display: inline-block;
    max-width: 250px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter-range {
    outline: 0;
    box-shadow: none;
    border: 1px solid #dadce0;
    border-radius: 4px;
    background-color: transparent;
    box-sizing: border-box;
    padding: 12px 8px 8px 8px;
}

.filter-range-type {
    width: 130px!important;
    font-size: .8rem!important;
}

.filter-range-values {
    display: inline-block;
    width: calc(100% - 130px);
}

.filter-range-value {
    display: inline-block;
    width: calc(100% - 130px);
    padding-left: 10px;
}
.filter-range-value input, .filter-range-values input {
    padding: .33rem .75rem!important;
}

.filter-range-values > div {
    display: inline-block;
    position: relative;
}

.filter-range-values div.from {
    margin-left: 13px;
    padding-right: 10px;
}

.filter-range .form-outline .form-control {
    min-height: 39px;
}
.on-page select {
    width: auto;
}

.table-on-page span {
    font-size: 14px;
    line-height: 14px;
    color: rgba(51, 51, 51, 0.5);
    padding-top: 10px;
}

.table-style {
    margin: 0 0 0 auto;
}

.table-on-page > button, .table-on-page > div > button, .table-style button, .table-pagination button {
    border: 1px solid rgba(209, 209, 213, 0.5)!important;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    padding: 5px;
    min-width: 40px;
    margin-left: 8px;
    color: rgba(51, 51, 51, 0.5);
}

.table-on-page > button.active, .table-style button.active, .table-pagination button.active {
    background: #FFFFFF;
    color: #333333;
}

.table-on-page .dropdown-toggle:after, .device-small-block .dropdown-toggle:after, .device-big-block .dropdown-toggle:after, td.align-middle .dropdown-toggle:after {
    content: none;
}


.table th {
    background: rgba(99, 99, 115, 0.5);
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 17px;
    padding-bottom: 16px;
    border: none!important;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    white-space: nowrap;
}

.table th.sortable {
    cursor: pointer;
}

.table td {
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    background: #FFFFFF;
}

.table .btn-link {
    text-decoration: underline;
    text-transform: none;
}

.table .btn-link:hover {
    color: var(--main-red-color);
}

.table-education-buttons {
    display: flex;
    min-width: 345px;
}

.table-education-buttons .btn-link {
    color: #000;
    text-transform: none;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.table-education-buttons i {
    color: #B1B1B9;
}

.table-group-actions {
    background: rgba(169, 169, 178, 0.3);
    margin-bottom: 1rem;
    padding: 10px;
}

.table-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.table-stage-number {
    opacity: 0.5;
    border: 1px solid #A9A9B2;
    border-radius: 8px;
}

.table-empty-block {
    border-radius: 4px;
    background-color: white;
}

.table-empty-block span {
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.table .avatar {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.table-responsive {
    /*min-height: 200px;*/
    padding-bottom: 200px;
    margin-bottom: -200px; 
}

.table .service-th {
    width: 55px;
}

.btn.btn-profile-page {
    text-transform: none;
    font-weight: 600;
    border: 1px solid rgba(99, 99, 115, 0.2);
    border-radius: 4px;
    color: #636373;
    box-shadow: none;
}

.active .btn.btn-profile-page {
    color: white;
    background: #B1B1B9;
    border: 1px solid #B1B1B9;
}

.profile .user-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
}

.profile-user-page-title {
    font-size: 17px;
    line-height: 17px;
}

.profile .avatar-block {
    position: relative;
}

.profile .avatar-block img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.profile .avatar-block button {
    background: white;
    color: #B1B1B9;
    border: 1px solid rgba(99, 99, 115, 0.2);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    outline: none;
}

.profile .avatar-block button.active {    
    height: 200px;
    width: 200px;
    font-size: 45px;
    border: none;
    border-radius: 0%;
}

.profile .avatar-block button:focus, .profile .avatar-block button:hover {
    background: #B1B1B9;
    color: white;
}

.profile .data-block .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 15px;
}

.profile .info-block {
    background: rgba(76, 185, 114, 0.2);
    border-radius: 4px;
    font-size: 13px;
    line-height: 24px;
}

.profile .info-block a {
    color: #333333;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.profile .password-block {
    background: rgba(99, 99, 115, 0.1);
    border-radius: 4px;
}

.profile .password-block input {
    background: white!important;
}

.profile .row-label {
    font-size: 15px;
}

.profile .policy {
    font-size: 14px;
}

.profile .notifications {
    font-size: 15px;
}
