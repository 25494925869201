.on-page select {
    width: auto;
}

.table-on-page span {
    font-size: 14px;
    line-height: 14px;
    color: rgba(51, 51, 51, 0.5);
    padding-top: 10px;
}

.table-style {
    margin: 0 0 0 auto;
}

.table-on-page > button, .table-on-page > div > button, .table-style button, .table-pagination button {
    border: 1px solid rgba(209, 209, 213, 0.5)!important;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    padding: 5px;
    min-width: 40px;
    margin-left: 8px;
    color: rgba(51, 51, 51, 0.5);
}

.table-on-page > button.active, .table-style button.active, .table-pagination button.active {
    background: #FFFFFF;
    color: #333333;
}

.table-on-page .dropdown-toggle:after, .device-small-block .dropdown-toggle:after, .device-big-block .dropdown-toggle:after, td.align-middle .dropdown-toggle:after {
    content: none;
}


.table th {
    background: rgba(99, 99, 115, 0.5);
    font-weight: bold;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-top: 17px;
    padding-bottom: 16px;
    border: none!important;
    user-select: none;
    white-space: nowrap;
}

.table th.sortable {
    cursor: pointer;
}

.table td {
    font-size: 14px;
    line-height: 22px;
    color: #333333;
    background: #FFFFFF;
}

.table .btn-link {
    text-decoration: underline;
    text-transform: none;
}

.table .btn-link:hover {
    color: var(--main-red-color);
}

.table-education-buttons {
    display: flex;
    min-width: 345px;
}

.table-education-buttons .btn-link {
    color: #000;
    text-transform: none;
    text-decoration: underline;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
}

.table-education-buttons i {
    color: #B1B1B9;
}

.table-group-actions {
    background: rgba(169, 169, 178, 0.3);
    margin-bottom: 1rem;
    padding: 10px;
}

.table-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.table-stage-number {
    opacity: 0.5;
    border: 1px solid #A9A9B2;
    border-radius: 8px;
}

.table-empty-block {
    border-radius: 4px;
    background-color: white;
}

.table-empty-block span {
    font-size: 14px;
    line-height: 22px;
    color: #333333;
}

.table .avatar {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.table-responsive {
    /*min-height: 200px;*/
    padding-bottom: 200px;
    margin-bottom: -200px; 
}

.table .service-th {
    width: 55px;
}
