@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Roboto:100,300,400");
@import 'mdb-react-ui-kit/dist/css/mdb.min.css';
@import 'react-toastify/dist/ReactToastify.min.css';
@import './auth-register.css';
@import './mdbootstrap-changes.css';
@import './sidebar.css';
@import './deviceItem.css';
@import './modal.css';
@import './toast.css';

:root {
    --main-red-color: #F1635A;
}

body {
    min-width: 450px;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
  
html,
body {
  height: 100%;
  background: #F0F0F1;  
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
}

#root {
    height: 100%;
}

.no-user-select {
    user-select: none;
}

.msg-warning {
    border: 1px solid var(--main-red-color);
    background-color: #feecec;
    padding: 8px 10px;
    color: var(--main-red-color);
    border-radius: .25rem;
}

.btn-rounded {
    border-radius: 100px;
}

.cursor-pointer {
    cursor: pointer;
}

.btn.btn-flat {
    font-weight: 500;
    color: inherit;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.spoiler-not-visible {
    display: none;
}

.spoiler-opened .spoiler-not-visible {
    display: block;
}

.spoiler-opened .spoiler-visible {
    display: none;
}

.react-select input {
    border: none!important;
    box-shadow: none!important;
}

.device-modal-program-stage-params {
    font-size: 13px;
    line-height: 18px;
}

.device-modal-program-stage-params i {
    width: 25px;
}

.dropdown-item > i {
    width: 20px;
}

ul.errors {
    list-style: none;
    padding: 0;
    font-size: 14px;
}

.page-title {
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
    background-color: white;
}

.progress-container {
    width: 100%;
    height: 6px;
    background: rgba(99, 99, 115, 0.15);
    border-radius: 100px;
}

.progress-value {
    background-color: #4CB972;
    height: 100%;
    border-radius: 100px;
}


.app-form .react-select input {
    height: 34px;
}

.app-form .row-label {
    font-size: 15px;
    line-height: 25px;
}

.app-form .row-btn {
    height: 48px;
}

.text-transform-none {
    text-transform: none;
}

.stage-list-item {
    border: 2px dashed rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px;
}

.stage-param-title-list .stage-param-item {
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    padding: 12px;
}

.stage-list .stage-list-item.active {
    background: rgba(76, 185, 114, 0.2);
    border: 2px dashed #4CB972;
}

.stage-param-title-list .stage-param-item.active {
    background: rgba(76, 185, 114, 0.2);
    border: 2px solid #4CB972;
}

.sortable-helper .stage-list-item {
    background-color: white;
    border: 2px dashed #4CB972;
    transform: rotate(2deg);
}

.sortable-helper .stage-item-actions {
    display: none;
}

.stage-list .stage-item-actions button {
    cursor: pointer;
    height: 100%;
}

.sortable-helper {
    z-index: 9999;
}

.wifi-indicator {
    width: 40px;
    display: inline-flex;
    align-items: flex-end;
    font-size: 12px;
    line-height: 12px;
}

.wifi-indicator > div {
    background-color: #C4C4C4;
    width: 25%;
}

.wifi-indicator > div + div {
    margin-left: 2px;
}

.wifi-indicator-description {
    margin-left: .5rem;
    vertical-align: super;
}

.default-block {
    background: #FFFFFF;
    border: 1px solid rgba(99, 99, 115, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
}

.default-block .block-title {
    font-size: 17px;
    line-height: 17px;
}

.default-block.page-title {
    font-size: 20px;
}

.default-block .block-title svg {
    vertical-align: baseline;
}

.default-block .block-title-value {
    margin-left: 5px;
    font-weight: 600;
}

.admin-block {
    opacity: 0.9;
    color: #636373;
}

.admin-block:hover {
    opacity: 1;
    color: #636373;
}

.admin-block:visited {
    color: #636373;
}

.admin-block span {
    font-weight: 600;
    font-size: 20px;
}

#app .link {
    color: #007bff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

#app .link:hover, .link:hover path {
    color: var(--main-red-color)!important;
    fill: var(--main-red-color)!important;
}

.link-hover:hover, .link-hover:hover path{
    color: var(--main-red-color)!important;
    fill: var(--main-red-color)!important;
}

.checkbox-itself, .radio-itself {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
}

.checkbox-itself[disabled] ~ .checkbox-styled {
  cursor: not-allowed; }

.checkbox-styled {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 0;
}

.checkbox-itself:focus ~ .checkbox-styled, .radio-itself:focus + .radio-styled {
    border-color: #00a8ff;
}

.toggle {
    width: 45px;
    height: 22px;
    position: relative;
    transition: .3s;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 15px;
    overflow: hidden;
}
.checkbox-itself:checked + .toggle {background: #82c250}
.checkbox-itself:not(:checked) + .toggle {background: #e74c3c}
.toggle:before {
    position: absolute;
}

.toggle:after {
    content: "";
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    transition: .3s;
    top: 1px;
}

.checkbox-itself:checked + .toggle:before{
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='12' height='9' fill='%23fff'%3E%3Cpolygon points='10.5,0.5 5,6 1.5,2.5 0,4 5,9 12,2'/%3E%3C/svg%3E");
    left: 5px;
    border: 0px solid silver;
}
.checkbox-itself:not(:checked) + .toggle:before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' height='10' fill='%23fff'%3E%3Cpolygon points='10,1.4 8.6,0 5,3.6 1.4,0 0,1.4 3.6,5 0,8.6 1.4,10 5,6.4 8.6,10 10,8.6 6.4,5'/%3E%3C/svg%3E");
    right: 5px;
    border: 0px solid silver;
    left:25px;
}
.checkbox-itself:checked + .toggle.lock:before{
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='13' height='11' fill='%23fff'%3E%3Cpath d='M8.8,5.2C8.6,5.1,8.5,5,8.2,5H0.7C0.5,5,0.4,5.1,0.2,5.2C0.1,5.4,0,5.5,0,5.7v4.5c0,0.2,0.1,0.4,0.2,0.5 C0.4,10.9,0.5,11,0.7,11h7.5c0.2,0,0.4-0.1,0.5-0.2C8.9,10.6,9,10.5,9,10.2V5.7C9,5.5,8.9,5.4,8.8,5.2z'/%3E%3Cpath d='M12,1c-0.7-0.7-1.5-1-2.5-1S7.7,0.3,7,1C6.3,1.7,6,2.5,6,3.5V5h1.5V3.5c0-0.6,0.2-1,0.6-1.4 c0.4-0.4,0.9-0.6,1.4-0.6s1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4V5H13V3.5C13,2.5,12.7,1.7,12,1z'/%3E%3C/svg%3E");
    left: 7px;
}
.checkbox-itself:not(:checked) + .toggle.lock:before {
    content: url("data:image/svg+xml;charset=utf8,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='9' height='11' fill='%23fff'%3E%3Cpath d='M8.8,5.2C8.6,5.1,8.5,5,8.2,5H8V3.5c0-1-0.3-1.8-1-2.5C6.3,0.3,5.5,0,4.5,0C3.5,0,2.7,0.3,2,1 C1.3,1.7,1,2.5,1,3.5V5H0.7C0.5,5,0.4,5.1,0.2,5.2C0.1,5.4,0,5.5,0,5.7v4.5c0,0.2,0.1,0.4,0.2,0.5C0.4,10.9,0.5,11,0.7,11h7.5  c0.2,0,0.4-0.1,0.5-0.2C8.9,10.6,9,10.5,9,10.3V5.7C9,5.5,8.9,5.4,8.8,5.2z M6.5,5h-4V3.5c0-0.6,0.2-1,0.6-1.4  c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4V5z'/%3E%3C/svg%3E");
    right: 7px;
}
.checkbox-itself:checked + .toggle:after{
    left: 24px;
}

.checkbox-itself:not(:checked) + .toggle:after {
    left: 1px;
}

.checkbox-itself:checked ~ .checkbox-styled:before {
    content: url("data:image/svg+xml,%3Csvg  xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='%23111b25'%3E%3Cpolygon points='13.8,0 5.5,12.4 1.5,7.8 0.5,9.4 5.6,15 15,1.1'%3E%3C/polygon%3E%3C/svg%3E");
    position: absolute;
    line-height: 0;
    top: -2px;
    width: 15px;
    height: 15px;
}

.upload-block > div {
    width: 100%;    
    font-size: 40px;
    border: 2px dashed rgba(0,0,0,0.2);
    padding: 30px 10px;
    cursor: pointer;
}

.upload-block > div:hover {
    border: 2px dashed rgba(0,0,0,0.5);
}

.rotate-right {
    animation:spin 1.5s linear infinite;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.hide-system-programs {
    font-size: 14px;
    margin-top: 2px;
}

.msg-block {
    padding: 15px;
    display: inline-block;
    border: 1px solid;
    font-size: 14px;
}

.msg-block.danger {
    background-color: #FFCDD2;
    color: #F44336;
}

.msg-block.success {
    background-color: #C8E6C9;
    color: #43A047;
}

.error_focused > div {
    border-color: red;
}
.select-label.error_focused {
    color: red;
}

.select-lg > div {
    padding-top: 3px;
    padding-bottom: 3px;
}

.select-lg + .select-label:not(.active) {
    padding-top: 5px;
}
