.btn.btn-profile-page {
    text-transform: none;
    font-weight: 600;
    border: 1px solid rgba(99, 99, 115, 0.2);
    border-radius: 4px;
    color: #636373;
    box-shadow: none;
}

.active .btn.btn-profile-page {
    color: white;
    background: #B1B1B9;
    border: 1px solid #B1B1B9;
}

.profile .user-name {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #636373;
}

.profile-user-page-title {
    font-size: 17px;
    line-height: 17px;
}

.profile .avatar-block {
    position: relative;
}

.profile .avatar-block img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
}

.profile .avatar-block button {
    background: white;
    color: #B1B1B9;
    border: 1px solid rgba(99, 99, 115, 0.2);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    outline: none;
}

.profile .avatar-block button.active {    
    height: 200px;
    width: 200px;
    font-size: 45px;
    border: none;
    border-radius: 0%;
}

.profile .avatar-block button:focus, .profile .avatar-block button:hover {
    background: #B1B1B9;
    color: white;
}

.profile .data-block .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 15px;
}

.profile .info-block {
    background: rgba(76, 185, 114, 0.2);
    border-radius: 4px;
    font-size: 13px;
    line-height: 24px;
}

.profile .info-block a {
    color: #333333;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.profile .password-block {
    background: rgba(99, 99, 115, 0.1);
    border-radius: 4px;
}

.profile .password-block input {
    background: white!important;
}

.profile .row-label {
    font-size: 15px;
}

.profile .policy {
    font-size: 14px;
}

.profile .notifications {
    font-size: 15px;
}