.wrapper {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

body > .wrapper {
    height: auto;
    min-height: 100%;
}

.main-header {
    position: relative;
    max-height: 100px;
    z-index: 1030;
}

.main-header .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 12px 15px;
    color: #B1B1B9!important;
}

.main-header .navbar {
    -webkit-transition: margin-left .3s ease-in-out;
    -o-transition: margin-left .3s ease-in-out;
    transition: margin-left .3s ease-in-out;
    margin-bottom: 0;
    margin-left: 275px;
    border: none;
    min-height: 50px;
    border-radius: 0;
    display: block;
    padding: 0;
    background: white;
    box-shadow: none;
}

.main-header .language-selector {
    float: left;
    padding: 12px 2px;
}

.main-header .navbar-custom-menu, .main-header .navbar-right {
    float: right;
}

.main-header .avatar {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.main-header .nav-link {
    user-select: none;
    padding-top: 13px;
    height: 50px;
    color: #636373;
}

.main-header .nav-link:hover {
    background: rgba(0,0,0,0.1);
}

.main-header .links {
    flex-direction: row;
}

.main-sidebar {
    background-color: #636373;
}

.main-sidebar a, .main-sidebar path{    
    color: rgba(169, 169, 178, 0.7);
    fill: rgba(169, 169, 178, 0.7);
}

.main-sidebar a:hover, .main-sidebar a:hover path {
    background-color: #545469;
    color: white;
    fill: white;
}


.main-sidebar a svg {
    width: 30px;
}

.main-sidebar a {
    white-space: nowrap;
    padding: .85rem 1rem;
    display: block;
}

.main-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 50px;
    min-height: calc(100% + 50px);
    width: 275px;
    z-index: 810;
    -webkit-transition: -webkit-transform .3s ease-in-out,width .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out,width .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out,width .3s ease-in-out;
    transition: transform .3s ease-in-out,width .3s ease-in-out;
}

.main-header .logo, .main-sidebar .logo {
    -webkit-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    width: 275px;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;
    background: white;
    color:#636373;
}

.main-header .logo-center {
    display: none;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    font-weight: 300;
    overflow: hidden;
    color:#636373;
    margin-left: 20px;
}

.main-header .logo .logo-lg, .main-sidebar .logo .logo-lg {
    display: block;
}

.main-header .logo .logo-mini, .main-sidebar .logo .logo-mini  {
    display: none;
}

.main-sidebar .logo {
    color: white;
    background: #636373;
    margin-top: -50px;
}

.main-header.sidebar-collapse .logo > .logo-mini, .main-sidebar.sidebar-collapse .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 35px;
}

.content-wrapper, .main-footer {
    -webkit-transition: -webkit-transform .3s ease-in-out,margin .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out,margin .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out,margin .3s ease-in-out;
    transition: transform .3s ease-in-out,margin .3s ease-in-out;
    margin-left: 275px;
    z-index: 820;
}

.content-wrapper {
    min-height: calc(100vh - 101px);
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-collapse .sidebar-menu li:hover {
    width: fit-content;
}

.sidebar-collapse .sidebar-menu li:hover  span {
    display: inline;
}

.main-header .dropdown-item:active {
    color: #fff!important;
    text-decoration: none;
    background-color: #636373;
}

.sidebar-menu span {
    font-weight: 600;
    margin-left: 15px;
    font-size: 14px;
    line-height: 24px;
}

.navbar-notifications {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    min-width: 10rem;
    padding: .5rem 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    width: 280px;
    padding-bottom: 0;
}

@media (min-width: 576px) {
    .navbar-notifications {
        width: 350px;
    }
}

@media (min-width: 1200px) { 
    .navbar-notifications {
        width: 400px;
    }
}

.navbar-notifications .notification-item .date {
    font-size: 12px;
    line-height: 12px;
    color: #636373;
}

.navbar-notifications .notification-item + .notification-item {
    border-top: 1px dashed #E0E0E3;
}

.navbar-notifications .notification-item .text {
    font-size: 14px;
    line-height: 24px;
    color: #333333;
}

.navbar-notifications .critical-state {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 2px;
    background-color: #4CB972;
}

.navbar-notifications .critical-state.critical {
    background-color: #F1635A;
}

.navbar-notifications .notification-more {
    background-color: #EEEEF0;
    color: rgba(91, 91, 91, 0.5);
    padding: 10px;
    font-size: 30px;
    font-weight: 300;
}

.notification-more a {
    color: rgba(91, 91, 91, 0.5);
}

.navbar-notifications .notification-more span {    
    margin-top: -15px;
}

.notification-link {
    color: #333333;
    text-decoration: underline;
    padding: 0!important;
}

.notification-count {
    background: #F1635A;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    right: 7px;
    color: white;
    font-size: 11px;
    line-height: 12px;
    padding: 4px 6px;
}

@media (max-width: 767px) {
    .main-sidebar {
        -webkit-transform: translate(-275px, 0);
        -ms-transform: translate(-275px, 0);
        -o-transform: translate(-275px, 0);
        transform: translate(-275px, 0);
    }

    .main-sidebar.sidebar-open {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    .main-header .logo {
        display: none;
    }

    .main-header .navbar {
        width: 100%;
        float: none;
    }

    .main-header .logo-center {
        display: block;
    }


    .content-wrapper, .main-footer {
        margin-left: 0;
    }

    .main-header .navbar {
        margin: 0;
    }
}

@media (min-width: 768px) {
    .sidebar-collapse.main-sidebar {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 65px !important;
        z-index: 850;
    }

    .sidebar-collapse.main-header .navbar {
        margin-left: 65px;
    }

    .main-header.sidebar-collapse .logo, .main-sidebar.sidebar-collapse .logo {
        width: 65px;
    }

    .sidebar-collapse .sidebar-menu span {
        display: none;
    }

    .content-wrapper.sidebar-collapse, .main-footer.sidebar-collapse {
        margin-left: 65px !important;
        z-index: 840;
    } 

    .main-header.sidebar-collapse .logo>.logo-lg, .main-sidebar.sidebar-collapse .logo>.logo-lg {
        display: none;
    }
}